/**
 * @module api-scm:logisticsProviderInformation
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsProviderInformation -- 物流商列表
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} purchaseState - 单据状态
 * @property {string} companyName - 公司名称
 *
 * @param {logisticsProviderInformation}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/logisticsProviderInformation/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取此供应商下的所有商品
 * @param {{keywords:string,supplierCode:string}}
 */
export function getSupplierProduct(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/getSupplierProduct',
    method: 'get',
    params
  })
}

/**
 *  树形下拉
 * @param {{optimizeCode:string}}
 */
export function getListDown(optimizeCode) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/get/getListDown',
    method: 'get',
    params: { optimizeCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/logisticsProviderInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 启用禁用
 * @param {{ids:Array<string|number>,status:number}} ids
 */
export function enableDisable({ id: ids, status }) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/updateDisableStatus',
    method: 'post',
    data: { ids, status },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{number:id}} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/withdrawClaim',
    method: 'post',
    data: { id: ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出物流理赔
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/dataExport',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/manualDockingOfKingdee',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 反审核
 */
export function reverseAudit(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsProviderInformation/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// /**
//  * @description 物流商信息撤销申请
//  */
// export function informationWithdraw(ids) {
//   return service['api-scm']({
//     url: '/scm/logistics/logisticsProviderInformation/withdrawClaim',
//     method: 'post',
//     data: { id: ids },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
