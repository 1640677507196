import * as auditHandleApi from '@/service/api-activiti/process/todo/handle'
import { isPlainObject, isArray, isString } from '@/utils/validate'
import { MAPPER } from './config'
import { getDocStatus } from '@/utils/uni-fields'
import { getSyncAsyncReturns } from '@/utils'

export default {
  /**
   * 按钮操作
   */
  name: 'buttonHandlerMixin',
  methods: {
    /**
     * 撤销
     * @param {{config:object,evtName:string,methodName:string,button:any,parent:any,resubmit?:boolean}}
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback?:('quiet'|'toast'|'console')}}
     */
    async withdraw({ config, evtName, methodName, button, resubmit = false }) {
      const selections = this.getSelections()
      this.loading(false)
      if (this.currentPageType === 'list') {
        if (!selections.length) {
          return {
            success: false,
            result: null,
            feedback: 'toast',
            message: '请先选择要撤销的项',
            description: 'losed list selections'
          }
        }
        if (selections.length > 1) {
          return void this.$message.error(
            '无法同时撤回多条数据或非审核中的数据'
          )
        }
        if (selections.every((v) => v.documentStatus !== 2)) {
          return void this.$message.error('当前订单非审核中，无需撤回')
        }
      }
      const submitMsg = `${resubmit ? '重新' : ''}撤销`
      const submitDesc = `${resubmit ? 're-' : ''}commit to progress`
      const isConfirm = await this.$confirm(`是否确定${submitMsg}?`, '提示', {
        type: 'warning'
      })

      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: `${submitMsg}: 弹窗二次确认取消`,
          description: `${submitDesc} : cancel by user`
        }
      }
      this.loading(true)
      try {
        const result = await this?.scope?.apiList?.withdraw(
          ['list'].includes(this.currentPageType)
            ? selections[0]?.id ?? ''
            : this?.scope?.id ?? ''
        )
        if (result.code === 200) {
          this.currentPageType === 'list'
            ? this?.scope?.$_list_crud_table_refresh()
            : this?.scope?.queryDetailById(this?.scope?.id)
          return {
            success: true,
            result: result.data,
            message: `${submitMsg}成功`,
            description: `submit: ${submitDesc} success`
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: `${submitMsg}失败`,
            description: `submit: ${submitDesc} fail`
          }
        }
      } catch (error) {
        return {
          success: false,
          result: error,
          feedback: 'toast',
          message: `${submitMsg}失败`,
          description: `submit: ${submitDesc} error`
        }
      }
    },
    /**
     * 下推
     * @see pushDown
     * ../pushDown.js
     */
    /**
     * 路由至新增页
     */
    create({ config, evtName, methodName, button }) {
      console.log('【buttonGroup】create')
      this.scope.$_list_crud_routePush('create')
      // TODO
      //  return {
      //    success: true,
      //    result: null,
      //    message:'',
      //    description: ''
      //  }
    },
    /**
     * @typedef {Object} HandleIntercept
     * @property {Function} allow
     * @property {(Object|null)} context this ref for allow fn
     *
     * @description 批量删除
     * @returns {({success:boolean,result:(null|any),message:string,description:string}|undefined)}
     */
    async delete({ config, evtName, methodName, button }) {
      const selections = this.getSelections()

      console.log('【buttonGroup】delete', selections)

      if (!selections.length) {
        return {
          success: false,
          result: null,
          feedback: 'toast',
          message: '请先勾选数据项',
          description: ''
        }
      }
      /**
       * @type {(Object|undefined|null)}
       * @description options for fn: $_list_crud_handleDelete of  src/mixins/CRUD/list
       */
      const handleDelete = button?.handleDelete

      /**
       * @type {HandleIntercept}
       * @description 默认的删除拦截器
       */
      const defaultHandleIntercept = {
        /**
         * @returns {{status:boolean,msg:string}}
         * @description 删除拦截处理
         * 审核流程的单据只有创建状态可以删除，
         * 非审核流的单据删除无需做任何条件判断（如: 物流询价单、组织管理无审核流）
         */
        allow: (selections) => ({
          status: selections.every((v) => {
            const approvalStatus = getDocStatus(v)
            return approvalStatus === 1 || typeof approvalStatus === 'undefined'
          }),
          msg: '只有创建状态可以执行删除操作，请重新选择'
        }),
        context: null // this ref for "allow"
      }
      /**
       * @type {(any|HandleIntercept)}
       * @description 配置的: 删除拦截器
       * - false => 前端不拦截
       * - 其余 => 均为前端默认删除拦截
       * - 另外可以配置 handleIntercept:HandleIntercept 覆盖默认
       * @see HandleIntercept
       */
      const handleIntercept = button?.handleIntercept ?? true
      /**
       * @type {(null|HandleIntercept)}
       * @description 最终的：删除拦截器
       * @see HandleIntercept
       */
      const _handleIntercept =
        handleIntercept === false
          ? null
          : isPlainObject(handleIntercept)
          ? { ...defaultHandleIntercept, ...handleIntercept }
          : defaultHandleIntercept

      // 前端拦截删除
      if (_handleIntercept) {
        if (typeof _handleIntercept?.allow !== 'function') {
          return {
            success: false,
            result: null,
            feedback: 'console',
            message: '删除拦截器配置错误',
            description: 'Invaild "handleIntercept.allow"'
          }
        }

        const [error, result] = await getSyncAsyncReturns(
          _handleIntercept.allow,
          typeof _handleIntercept?.context === 'object' &&
            !isArray(_handleIntercept.context)
            ? _handleIntercept.context
            : null,
          selections
        )
        if (error) {
          console.error(error)
          return {
            success: false,
            result: null,
            feedback: 'console',
            message: '删除拦截器执行错误',
            description: 'Error in "handleIntercept.allow"'
          }
        } else {
          if (typeof result?.status !== 'boolean' || !isString(result?.msg)) {
            return {
              success: false,
              result: result,
              feedback: 'console',
              message: '删除拦截器返回结果异常',
              description: 'Invalid result return from "handleIntercept.allow"'
            }
          }

          if (result.status === false) {
            return {
              success: false,
              result: result,
              feedback: result?.msg ? 'toast' : 'console',
              message: result?.msg || '删除拦截器拦截成功',
              description: 'Intercepted by "handleIntercept.allow"'
            }
          }
        }
      }

      const _handleDelete =
        handleDelete === null ||
        typeof handleDelete === 'undefined' ||
        isPlainObject(handleDelete)
          ? {
              confirm: true,
              keyofIds: 'ids',
              selections,
              ...(handleDelete ?? {})
            }
          : null

      if (!isPlainObject(_handleDelete)) {
        return {
          success: false,
          result: null,
          feedback: 'console',
          message: '配置错误: handleDelete',
          description:
            'Invalid "handleDelete", must be a plain object, please check the button item'
        }
      }

      if (typeof this.scope?.$_list_crud_handleDelete !== 'function') {
        return {
          success: false,
          result: null,
          feedback: 'console',
          message: '未找到 $_list_crud_handleDelete',
          description: 'Losed the "$_list_crud_handleDelete"'
        }
      }

      const success = await this.scope.$_list_crud_handleDelete(_handleDelete)

      return success === true
        ? {
            success,
            result: null,
            feedback: 'console', // 成功的消息提示在历史代码中 see $_list_crud_handleDelete's remove.apply, 此处无需 toast
            message: '删除成功',
            description: 'Exec "$_list_crud_handleDelete" success'
          }
        : {
            success: false,
            result: null,
            feedback: 'console',
            message: '删除失败',
            description: 'Exec "$_list_crud_handleDelete" failed'
          }
    },
    /**
     * 提交
     * @param {{config:object,evtName:string,methodName:string,button:any,parent:any}}
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback:('quiet'|'toast'|'console')}}
     */
    async submit({ config, evtName, methodName, button }) {
      console.log('【buttonGroup】submit')

      if (this.currentPageType === 'list') {
        const selections = this.getSelections()
        console.log('selections:', selections)
        if (!selections.length) {
          return {
            success: false,
            result: null,
            feedback: 'toast',
            message: '请先选择要提交的项',
            description: 'losed list selections'
          }
        }

        if (typeof button?.allowSubmit === 'function') {
          const { status = true, msg } = await button.allowSubmit({
            selections
          })

          if (status !== true) {
            return {
              success: false,
              result: null,
              feedback: msg ? 'toast' : 'console',
              message: msg || '无法提交，请检查勾选的单据信息',
              description: 'reject by button.allowSubmit'
            }
          }
        }
        // 列表页面提交按钮
        return await this.commitToProcess({
          config,
          evtName,
          methodName,
          button,
          resubmit: false
        })
      } else {
        const { $_detail_cru_validate, $_detail_cru_processInfo, id } =
          this.scope
        if (typeof button?.allowSubmit === 'function') {
          const { status = true, msg } = await button.allowSubmit({
            id,
            processInfo: $_detail_cru_processInfo
          })
          if (status !== true) {
            return {
              success: false,
              result: null,
              feedback: msg ? 'toast' : 'console',
              message: msg || '无法提交，请检查勾选的单据信息',
              description: 'reject by button.allowSubmit'
            }
          }
        }

        // 待办列表进入
        if ($_detail_cru_processInfo) {
          // const { assignee } = $_detail_cru_processInfo
          // console.log('assignee', assignee)
          // if (assignee) {
          //   if (assignee !== this.$store.getters.name) {
          //     return {
          //       success: false,
          //       result: null,
          //       feedback: 'toast',
          //       message: '提交程序异常，请联系管理员',
          //       description: `submit: 审核人与当前账号登录不符, current user:${this.$store.getters.name}, assignee user:${assignee}`
          //     }
          //   }
          // } else {
          //   const claimed = await this.claimTask(
          //     this.scope?.$_detail_cru_processInfo.id
          //   )
          //   if (!claimed.success) return claimed
          // }

          const { processHandle } = this.scope.$refs['backlog-audit-tab']

          const { auditStatus } = $_detail_cru_processInfo

          if (processHandle.form.data.isPass === 1) {
            if (button?.config?.skipValidate !== true) {
              const { valid } = await $_detail_cru_validate.call(this.scope)
              if (valid !== true) {
                return {
                  success: false,
                  result: null,
                  feedback: 'console',
                  message:
                    '必填字段校验证未通过 (commit to process or complete process)',
                  description: `submit: valid error[1]`
                }
              }
            }
            if (auditStatus === 1) {
              // 被驳回的单据 重新提交到审核流
              return await this.commitToProcess({
                config,
                evtName,
                methodName,
                button,
                resubmit: true
              })
            } else {
              // 审核通过：审核通过到审核流下一节点
              return await this.completeProcess({
                config,
                evtName,
                methodName,
                button
              })
            }
          } else if (processHandle.form.data.isPass === 0) {
            const form =
              this.scope.$refs['backlog-audit-tab'].processHandle.form
            if (
              button.enableValidation &&
              form.getItem('remarks')?.label === '驳回理由'
            ) {
              return form.data.remarks
                ? await this.rejectProcess({
                    config,
                    evtName,
                    methodName,
                    button
                  })
                : {
                    success: false,
                    result: null,
                    feedback: 'console',
                    message:
                      '必填字段校验证未通过 (commit to process or complete process)',
                    description: `submit: valid error[1]`
                  }
            } else {
              // 驳回
              return await this.rejectProcess({
                config,
                evtName,
                methodName,
                button
              })
            }
          } else {
            return {
              success: false,
              result: null,
              feedback: 'toast',
              message: '提交程序异常，请联系管理员',
              description: `submit: unknown "isPass":${processHandle.form.data.isPass} `
            }
          }
        } else {
          // 非待办列表进入
          const {
            $_detail_cru_documentStatusName,
            $_detail_cru_workOrderStatusName
          } = this.scope

          if (
            $_detail_cru_documentStatusName === '重新审核' ||
            $_detail_cru_documentStatusName === '创建' ||
            $_detail_cru_workOrderStatusName === '创建'
          ) {
            if (button?.config?.skipValidate !== true) {
              const { valid } = await $_detail_cru_validate.call(this.scope)
              if (valid !== true) {
                return {
                  success: false,
                  result: null,
                  feedback: 'console',
                  message: '必填字段校验证未通过 (commit to process)',
                  description: `submit: valid error[2]`
                }
              }
            }
            // 提交到审核流或者业务流(如：客服工单）
            return await this.commitToProcess({
              config,
              evtName,
              methodName,
              button,
              resubmit: false
            })
          } else {
            return {
              success: false,
              result: null,
              feedback: 'toast',
              message: '提交程序异常，请联系管理员',
              description: `submit: illegal "$_detail_cru_documentStatusName":${$_detail_cru_documentStatusName} or "$_detail_cru_workOrderStatusName":${$_detail_cru_workOrderStatusName}`
            }
          }
        }
      }
    },
    /**
     * 提交: 提交到审核流或者业务流(如：提交客服工单）
     * @param {{config:object,evtName:string,methodName:string,button:any,parent:any,resubmit?:boolean}}
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback?:('quiet'|'toast'|'console')}}
     */
    async commitToProcess({
      config,
      evtName,
      methodName,
      button,
      resubmit = false
    }) {
      this.loading(false)
      console.log('commitToProcess')
      if (this.currentPageType === 'list') {
        const selections = this.getSelections()
        if (!selections.length) {
          return {
            success: false,
            result: null,
            feedback: 'toast',
            message: '请先选择要提交的项',
            description: 'losed list selections'
          }
        }
        console.log('selections:', selections)
      }
      const submitMsg = `${resubmit ? '重新' : ''}提交`
      const submitDesc = `${resubmit ? 're-' : ''}commit to progress`

      const isConfirm = await this.$confirm(`是否确定${submitMsg}?`, '提示', {
        type: 'warning'
      })
      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: `${submitMsg}: 弹窗二次确认取消`,
          description: `${submitDesc} : cancel by user`
        }
      }
      this.loading(true)
      try {
        const [err, send] = await this.getSendFromButton(button, evtName)
        const getCustomApiParams = button?.on?.getCustomApiParams
        if (err) return err
        const { apiList, $_detail_cru_processInfo } = this.scope
        const submitApi = resubmit ? auditHandleApi.resubmit : apiList.submit
        const submitReq = resubmit
          ? {
              processInstanceId: $_detail_cru_processInfo.procInstId,
              taskId: $_detail_cru_processInfo.id,
              remarks:
                this.scope.$refs['backlog-audit-tab'].processHandle.form.data
                  .remarks
            }
          : send || this.getIds().join()

        const result = await submitApi(
          getCustomApiParams
            ? getCustomApiParams(resubmit, submitReq, this.getIds().join())
            : submitReq
        )
        if (result.code === 200) {
          /**
           * @description (多货主）出、入库单的提示文案存在data中或msg中
           */
          return {
            success: true,
            res: result,
            feedback:
              typeof button?.config?.xhr?.message === 'function'
                ? 'custom'
                : 'console',
            result: result.data,
            message: result?.msg?.length
              ? result.msg
              : typeof result?.data === 'string'
              ? result?.data
              : `${submitMsg}成功`,
            description: `submit: ${submitDesc} success`
          }
        } else {
          return {
            success: false,
            res: result,
            result,
            feedback:
              typeof button?.config?.xhr?.message === 'function'
                ? 'custom'
                : 'console',
            message: result.msg.length
              ? result.msg
              : typeof result?.data === 'string'
              ? result.data
              : `${submitMsg}失败`,
            description: `submit: ${submitDesc} fail`
          }
        }
      } catch (error) {
        return {
          res: null,
          success: false,
          result: error,
          feedback: 'toast',
          message: `${submitMsg}失败`,
          description: `submit: ${submitDesc} error`
        }
      }
    },
    /**
     * 提交：审核通过
     * @param {{config:object,evtName:string,methodName:string,button:any}}
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback:('quiet'|'toast'|'console')}}
     */
    async completeProcess({ config, evtName, methodName, button }) {
      console.log('completeProcess')
      this.loading(false)
      const isConfirm = await this.$confirm(' 是否审核通过?', '提示', {
        type: 'warning'
      })
      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: '审核: 弹窗二次确认取消',
          description: 'complete process: cancel by user'
        }
      }
      this.loading(true)
      try {
        const { $_detail_cru_processInfo } = this.scope
        const { processHandle } = this.scope.$refs['backlog-audit-tab']
        // const result = await auditHandleApi.complete({
        //   processInstanceId: $_detail_cru_processInfo.procInstId,
        //   taskId: $_detail_cru_processInfo.id,
        //   remarks: processHandle.form.data.remarks
        // })
        const result = await auditHandleApi.completeTasks({
          instanceTaskDTO: [
            {
              processInstanceId: $_detail_cru_processInfo.procInstId,
              taskId: $_detail_cru_processInfo.id
            }
          ],
          remarks: processHandle.form.data.remarks
        })
        const { code, data, msg, description } = result

        if (code === 200) {
          this.$emit('btn-success')
          return {
            success: true,
            result: data,
            message: '审核成功',
            description: 'submit: completeProcess success'
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: '审核失败',
            description: `submit: completeProcess fail, ${msg}, ${description}`
          }
        }
      } catch (error) {
        return {
          success: false,
          result: error,
          feedback: 'toast',
          message: '审核失败',
          description: 'submit: completeProcess error'
        }
      }
    },
    /**
     * 提交：驳回
     * @param {{config:object,evtName:string,methodName:string,button:any}}
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback:('quiet'|'toast'|'console')}}
     */
    async rejectProcess({ config, evtName, methodName, button }) {
      console.log('rejectProcess')
      this.loading(false)
      const isConfirm = await this.$confirm(`是否确定驳回?`, '提示', {
        type: 'warning'
      })
      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: '审核: 弹窗二次确认取消',
          description: 'complete process: cancel by user'
        }
      }
      this.loading(true)
      try {
        const { id, apiList, $_detail_cru_processInfo } = this.scope
        const { processHandle } = this.scope.$refs['backlog-audit-tab']
        const result = await apiList.reject({
          id,
          taskId: $_detail_cru_processInfo.id,
          remarks: processHandle.form.data.remarks
        })
        const { code, data, msg, description } = result
        if (code === 200) {
          return {
            success: true,
            result: data,
            message: '驳回成功',
            description: 'submit: rejectProcess success'
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: '驳回失败',
            description: `submit: rejectProcess failed, ${msg}, ${description}`
          }
        }
      } catch (error) {
        return {
          success: false,
          result: error,
          feedback: 'toast',
          message: '驳回失败',
          description: 'submit: rejectProcess error'
        }
      }
    },
    /**
     * 提交：驳回
     * @returns {{success:boolean,result:(null|any),message:string,description:string,feedback:('quiet'|'toast'|'console')}}
     * @deprecated 拾取由后端直接处理
     * @see #2026 禅道需求
     */
    async claimTask(id) {
      const result = await auditHandleApi.claim(id)
      const { code, data, msg, description } = result
      return code === 200
        ? {
            success: true,
            result: data,
            feedback: 'quiet',
            message: '任务拾取成功',
            description: 'submit: claimTask success'
          }
        : {
            success: false,
            result,
            feedback: 'console',
            message: '任务拾取失败',
            description: `submit: claimTask failed, ${msg}, ${description}`
          }
    },
    /**
     * @emits click
     * @emits success
     * @emits fail
     */
    async hanldeClickButton({ $event, item }) {
      if (!isPlainObject(item)) {
        console.groupCollapsed(`【buttonGroup】${JSON.stringify(item)}`)
        console.error(
          `
错误类型: 子按钮数据类型错误
来源:     ${window?.location?.pathname}
URL:     ${window?.location?.href}`,
          item
        )
        return void console.groupEnd()
      }

      const evtName =
        item.evtName && isString(item.evtName) ? item.evtName : item.label

      console.log('button:', item)
      console.log('$event:', $event)
      console.groupCollapsed(`【buttonGroup】${evtName}`)

      this.$emit('click', { button: item, ...$event }) // @click 事件永远出发
      if (!evtName || !isString(evtName)) {
        console.warn(`
警告: 未提供 "evtName" 或者 "label"
备注: 可以 <button-group @click=... 监听处理`)
        return void console.groupEnd()
      } else {
        console.info(`
信息: 已经发射了 click 事件到外部
备注: 可以 <button-group @click=... 监听处理`)
      }

      const config = MAPPER[evtName] || {}
      const { methodName = '' } = config

      if (
        isString(methodName) &&
        methodName &&
        typeof this[methodName] === 'function'
      ) {
        // loading start...
        if (evtName !== '下推') {
          this.loading(true)
        }
        const handlerArgs = {
          config,
          evtName,
          methodName,
          button: item
        }

        const handlerResult = await this[methodName](handlerArgs)

        console.log(handlerResult, 'handlerResult======>>>>>>>>>>')
        if (!handlerResult) {
          console.info(`
信息:   已执行但没有返回结果
备注:   弹窗二次确认点击了取消`)
          // loading end...
          this.loading(false)
          return void console.groupEnd()
        }
        const {
          res = null,
          success = false,
          result = null,
          feedback = 'console',
          message = '',
          description = ''
        } = handlerResult

        const callbackArgs = {
          res,
          config,
          evtName,
          methodName,
          result,
          feedback,
          message,
          description,
          button: item
        }

        success
          ? typeof item?.callback?.success === 'function'
            ? item.callback.success(callbackArgs)
            : this.handleSuccess(callbackArgs)
          : typeof item?.callback?.fail === 'function'
          ? item.callback.fail(callbackArgs, this.scope)
          : this.handleFail(callbackArgs)
        // loading end...
        this.loading(false)
        // @success or @fail 事件永远出发, 与 handleSuccess，handleFail 叠加
        const emitCallbackName = success ? 'success' : 'fail'
        this.$emit(emitCallbackName, {
          ...callbackArgs,
          ...$event
        })
        console.info(`
信息:   已发射了 ${emitCallbackName} 回调事件到外部,
备注:   可以通过 <button-group @${emitCallbackName}=...  监听处理`)
      } else {
        console.error(`
按钮:    ${evtName}
方法:    ${methodName || 'N/A'}
错误类型: 内部未配置按钮点击处理器
来源:    ${window?.location?.pathname}
URL:    ${window?.location?.href}
备注:    如果外部配置了事件或者监听事件并处理，请忽略此错误`)
        // this.$emit('fail', {
        //   evtName,
        //   methodName,
        //   result: null,
        //   description: '未找到点击事件处理器 (按钮)',
        //   button: item,
        //   ...$event
        // })
      }
      console.groupEnd()
    }
  }
}
