/**
 * @module api-scm:logisticsClaim
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsClaim -- 物流理赔
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} claimCode - 单据编号
 * @property {string} logisticsProvidersName - 物流商
 * @property {string} sourceOrderNumber - 源单订单号
 * @property {string} createTimeEnd - 结束时间
 * @property {string} createTimeStart - 开始时间
 * @property {string} cancellationStatus - 作废
 *
 * @param {logisticsClaim}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/logisticsClaim/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/update',
    method: 'put',
    data
  })
}

/**
 *  树形下拉
 * @param {{code:string}}
 */
export function getListDown(claimCode) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/get/getListDown',
    method: 'get',
    params: { claimCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/logisticsClaim/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {(string|number)} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {{obejct}} data
 * @returns
 */
export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出物流理赔
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsClaim/dataExport',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}
