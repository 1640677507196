/**
 * @module api-web:clientServe/contractOrder
 */
import service from '@/service'

/**
 * 合同订单查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * businessState: number ,
 * clientCode: string,
 * documentStatus: number,
 * isMonthly: number,
 * |number
 * @param {string}: string
 * }} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/get/getList',
    method: 'post',
    data
  })
}

export function info(id) {
  return service['api-web']({
    url: '/sell/order/orderContract/info',
    method: 'get',
    params: { id }
  })
}

export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/orderContract/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/save',
    method: 'post',
    data
  })
}

/**
 * 提交
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/sell/order/orderContract/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-web']({
    url: '/public/activiti/orderContract/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/update',
    method: 'post',
    data
  })
}
/**
 * @param {{id:number | string,path:string}} data
 * @description 导入
 */
export function importData(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/saveContract',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/sell/order/orderContract/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/sell/order/orderContract/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/sell/order/orderContract/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/sell/order/orderContract/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {Array<string|number>} ids
 * @return {object}
 * @description 确认收款
 */
export function receiptConfirmation(ids) {
  return service['api-web']({
    url: '/sell/order/orderContract/receiptConfirmation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @param {{orderCode:number | string,path:string}} data
 * @returns {object}
 * @description 上传收款凭证
 */
export function uploadReceiptVoucher(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/uploadReceiptVoucher',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 *
 * @description 合同明细导入模板下载
 */
export function downloadForDetail(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/downloadForDetail',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 合同明细数据Excel导入
 */
export function importContrastOrderDetailData(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/importContrastOrderDetailData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
/**
 * 模糊查询商品code的下拉
 * @param {{code?:string,supplierCode?:string}} params
 */
export function getAncillaryCode(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryCode',
    method: 'get',
    params
  })
}
/**
 * 合同订单导出数据
 * @param {{code?:string,supplierCode?:string}} params
 */
export function orderContractExport(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/orderContractExport',
    method: 'post',
    data
  })
}

/**
 *
 * @param {(string|number)} data
 * @description 树形表格下拉
 */
export function getListDown(data) {
  return service['api-web']({
    url: '/sell/order/orderContract/get/getListPull',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
