<template>
  <el-row v-show="display" v-loading="loading">
    <el-col :span="24">
      <el-empty
        v-if="mode !== 'create' && xml === '' && loading === false"
        :image-size="EL_EMPTY_IMAGE_SIZE"
        description="加载失败"
      >
        <el-button
          v-if="type === 'definition'"
          type="primary"
          @click="handleExit"
        >
          退出
        </el-button>
      </el-empty>
      <component
        :is="is"
        v-else
        :id="id"
        :type="type"
        :timestamp="timestamp"
        :prefix="prefix"
        :process-id="processId"
        :process-key="processKey"
        :process-name="processName"
        :process-version="processVersion"
        :process-submitter="processSubmitter"
        :instance-node-through="history.node"
        :instance-line-flows="history.line"
        :file-name="fileName"
        :xml="xml"
        @stage="handleStage"
        @release="handleRelease"
        @exit="handleExit"
        @update-process-key="$emit('update-process-key', $event)"
        @update-process-name="$emit('update-process-name', $event)"
      />
    </el-col>
  </el-row>
</template>
<script>
import { upload, enable } from '@/service/api-activiti/process/definition'
import {
  getProcessDiagramXML,
  getProcessInstHistory,
  toggleEnable
} from '@/utils/request'
import { isNumeric } from '@/utils/validate'
import { EL_EMPTY_IMAGE_SIZE } from '@/constants'
import { isString } from 'min-dash'

/**
 * @typedef {Object} BpmnData BPMN data that emited from './bpmn/core'
 * @property {HTMLElement} doc - covert from xml string via xmlStr2xmlDoc of './bpmn/utils/xml.js'
 * @property {object} obj - covert from xml string via xml2js of 'xml-js'
 * @property {string} str - xml string
 * @property {Blob} blob - xml blob
 * @property {File} file - xml file
 */
export default {
  name: 'ProcessDesign',
  components: {
    Editor: () => import('./editor'),
    Viewer: () => import('./viewer'),
    'no-permission': () => import('@/views/error/401')
  },
  props: {
    /**
     * 嵌套在弹窗中显隐
     */
    display: {
      type: Boolean,
      default: true
    },
    /**
     * 流程图类型: 模板定义/流程实例
     * default : 'definition'
     */
    type: {
      type: String,
      default: 'definition',
      validator: (type) => ['definition', 'instance'].includes(type)
    },
    /**
     * 模式:
     * 创建，编辑，查看
     */
    mode: {
      type: String,
      default: 'create',
      validator: (mode) => ['create', 'edit', 'view'].includes(mode)
    },
    /**
     * 流程设计列表主键 ID
     */
    id: {
      type: [String, Number],
      default: ''
    },
    /**
     * processName
     */
    processName: {
      type: String,
      default: ''
    },
    /**
     * processId
     * "create" mode without processId
     */
    processId: {
      type: String,
      default: ''
    },

    /**
     * processKey
     * "create" mode without processId
     */
    processKey: {
      type: String,
      default: ''
    },
    /**
     * processVersion
     * "create" mode default 1
     */
    processVersion: {
      type: [String, Number],
      default: 1,
      validator: (v) => isNumeric(v)
    },
    /**
     * diagram file name that saved
     * "create" mode without fileName
     */
    fileName: {
      type: String,
      default: ''
    },
    /**
     * definition xml string
     * if read remotely, will covered by fetched remote xml
     */
    data: {
      type: String,
      default: '',
      required: false
    },
    /**
     * 流程实例 ID
     */
    instanceId: {
      type: [String, Number],
      default: ''
    },
    /**
     * 单据ID
     */
    documentCode: {
      type: String,
      default: ''
    },
    /**
     * 流程实例提交人/发起人 用户名/ID
     */
    processSubmitter: {
      type: Object,
      default: () => ({ id: '', name: '' }),
      validator: (obj) => {
        return !!(isString(obj?.id) && isString(obj?.name))
      }
    }
  },
  data() {
    return {
      EL_EMPTY_IMAGE_SIZE,
      loading: true,
      timestamp: Date.now(),
      /**
       * @type {('camunda'|'activiti'|'flowable')}
       * @description 流程引擎
       */
      prefix: 'activiti',
      /**
       * 流程 XML
       */
      xml: '',
      /**
       * 实例流转历史数据
       * @type {{node:Array<string>,line:Array<string>}}
       */
      history: {
        node: [], // 环节(节点) IDs
        line: [] // 流(线) IDs
      }
    }
  },
  computed: {
    renderTrigger() {
      return `${this.display}${this.type}${this.mode}${this.data}${this.instanceId}`
    },
    hasRemoteSavedXML() {
      return this.processId && this.fileName
    },
    is() {
      switch (this.mode) {
        case 'create':
        case 'edit':
          return 'Editor'
        case 'view':
          return 'Viewer'
        default:
          console.error(
            '[Process Designer Index Error]: Unknown "mode" for "ProcessDesign"',
            this.mode
          )
          break
      }
      return 'NoPermission'
    }
  },
  watch: {
    renderTrigger: {
      immediate: true,
      handler() {
        if (this.display === true) this.init()
        else this.loading = true
      }
    }
  },
  methods: {
    /**
     * init
     * @description
     *
     * 一、实例
     * 1. 获取流程实例图 xml
     * 2. 获取流程实例图 流程记录
     *     - 流程历史记录：流（线）
     *     - 流程历史记录：环节（节点）
     *
     * 二、定义
     * 1. Viewer
     *      - 优先 加载 data, 没有 data 则根据 processId 和 fileName 远程获取回显
     * 2. Editor
     *      - edit 根据 processId 和 fileName 远程获取
     *      - create 直接进入
     */
    async init() {
      this.loading = true
      this.timestamp = `${Date.now()}`
      try {
        if (this.type === 'instance') {
          if (this.instanceId) await this.initProcessInstance()
          else {
            this.resetProcessInstHistory()
            this.xml = ''
            console.info('[Process Designer Index Info]: Losed "instanceId"')
          }
        } else {
          this.resetProcessInstHistory()

          if (this.is === 'Viewer') {
            if (this.data) this.xml = this.data
            else {
              if (this.hasRemoteSavedXML) {
                this.xml = await this.handleFetch('definition')
              } else {
                this.xml = ''
                console.warn(
                  `[Process Designer Index Warning]: ${this.is} losed "processId" or "fileName", maybe waitting for xml data`,
                  this.processId,
                  this.fileName
                )
              }
            }
          } else if (this.is === 'Editor') {
            if (this.mode === 'create') {
              this.xml = ''
              console.info(
                '[Process Designer Index Info]: Current is create mode'
              )
            } else {
              if (this.data) this.xml = this.data
              else {
                if (this.hasRemoteSavedXML) {
                  this.xml = await this.handleFetch('definition')
                } else {
                  this.xml = ''
                  console.warn(
                    `[Process Designer Index Warning]: ${this.is} losed "processId" or "fileName", maybe waitting for xml data`,
                    this.processId,
                    this.fileName
                  )
                }
              }
            }
          } else {
            this.xml = ''
            console.error(
              '[Process Designer Index Error]: Diagram Illegal, show "NoPermission"'
            )
          }
        }
      } catch (error) {
        console.error(error)
        this.resetProcessInstHistory()
        this.xml = ''
      }
      this.$halt(64, () => (this.loading = false))
    },
    /**
     * 流程实例: 需要流转历史+xml
     */
    async initProcessInstance() {
      const history = await getProcessInstHistory(
        this.instanceId,
        this.documentCode,
        'both'
      )
      console.groupCollapsed('流程图')

      history.node = history.node
        // .filter((v) => v.actType !== 'endEvent')
        .map((v) => v.actId)

      history.line = history.line.filter((v) => isString(v) && !!v.trim())

      this.history = history

      this.xml = await this.handleFetch('instance')
    },
    /**
     * 流程实例：重置流转历史
     */
    resetProcessInstHistory() {
      this.history = {
        node: [],
        line: []
      }
    },
    /**
     * 读取流程 定义or实例
     * @param {('definition'|'instance')} [type='definition']
     */
    async handleFetch(type = 'definition') {
      try {
        const xml =
          type === 'definition'
            ? await getProcessDiagramXML(
                'definition',
                this.processId,
                this.fileName
              )
            : await getProcessDiagramXML('instance', this.instanceId)
        if (!xml) {
          this.$message.error('流程图加载失败')
          void console.error(
            '[Process Designer Index Error]: Diagram XML Fetched Failed'
          )
        }

        return xml
      } catch (error) {
        console.error(
          '[Process Designer Index Error]: Diagram XML Fetched Error ' + error
        )
      }
      return ''
    },
    /**
     * 上传流程定义 xml 文件
     * @param {BpmnData} bpmnData
     */
    async handleSubmit(bpmnData) {
      console.info('[Process Designer BPMN Data]: ', bpmnData)

      try {
        return await upload({
          id: this.id,
          name: this.processName,
          multipartFile: [bpmnData.file.stream, bpmnData.file.name]
        })
      } catch (err) {
        console.error(
          '[Process Designer Index Error]: Diagram File Submitted Error ' + err
        )
      }
      return { code: -1, data: {}, msg: '' }
    },
    /**
     * 保存草稿
     * @param {BpmnData} bpmnData
     */
    async handleStage(bpmnData) {
      try {
        const submitResult = await this.handleSubmit(bpmnData)
        const {
          code,
          data: { deploymentId, fileName, processName }
        } = submitResult
        if (code === 200 && deploymentId && fileName && processName) {
          this.handleExit()
          await this.$halt(200)
          this.$message({ type: 'success', message: '保存成功' })
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 保存发布
     * @param {BpmnData} bpmnData
     */
    async handleRelease(bpmnData) {
      try {
        const submitResult = await this.handleSubmit(bpmnData)
        const {
          code,
          data: { deploymentId, fileName, processName }
        } = submitResult
        if (code === 200 && deploymentId && fileName && processName) {
          // 发布
          const { code: _code, data: _data } = await toggleEnable({
            // confirm: false,
            api: enable,
            id: { key: 'id', val: this.id },
            state: { key: 'state', val: 2 },
            map: { 2: '发布', 3: '不发布' },
            cb: {
              error: (err) =>
                void console.error(
                  '[Process Designer Index Error] Enable ' + _code + ': ' + err
                )
              // success: () => {},
              // complete: () => {}
            },
            message: false
          })
          if (_code === 200 && _data) {
            this.handleExit()
            await this.$halt(200)
            this.$message({ type: 'success', message: '发布成功' })
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 退出
     */
    handleExit() {
      this.$emit('exit')
    }
  }
}
</script>
<style lang="scss" scoped>
.process-design-container {
  min-width: 100%;
  min-height: 100%;
}
// 违背 bpmn 开源协议证书，隐藏 logo
:deep(.bjs-powered-by) {
  display: none;
}
// no-permission remove list
// :deep(.list-unstyled) {
//   display: none;
// }
</style>
