<template>
  <el-dialog
    v-el-drag-dialog
    class="base-dialog"
    :visible.sync="proxyVisible"
    :title="title"
    :width="width"
    :lock-scroll="lockScroll"
    :close-on-click-modal="closeOnClickModal"
    :close-on-press-escape="closeOnPressEscape"
    :destroy-on-close="destroyOnClose"
    v-bind="$attrs"
    :show-close="proxyShowClose"
    :top="marginTop"
    :style="{ height: dialogHeight }"
    v-on="$listeners"
  >
    <slot />
    <div v-if="showFooter" slot="footer" class="footer-container">
      <div class="footer-tips">
        <slot name="footer-tips" />
      </div>
      <div class="footer">
        <slot name="footer" />
        <template v-if="!$slots['footer']">
          <el-button
            v-if="showCancelBtn"
            :disabled="loading"
            @click="$emit('cancel', $event)"
          >
            {{ cancelBtn }}
          </el-button>
          <el-button
            v-if="showConfirmBtn"
            :disabled="confirmDisabled"
            type="primary"
            :loading="loading"
            @click="$emit('confirm', $event)"
          >
            {{ confirmBtn }}
          </el-button>
        </template>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '800px'
    },
    title: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    showConfirmBtn: {
      type: Boolean,
      default: true
    },
    dialogHeight: {
      type: String,
      default: 'auto'
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    marginTop: {
      type: String,
      default: '15vh'
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    confirmBtn: {
      type: String,
      default: '确 定'
    },
    cancelBtn: {
      type: String,
      default: '取 消'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    proxyVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    proxyShowClose() {
      if (this.loading === true) return false
      else return this.showClose
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-container {
  position: relative;
  .footer-tips {
    position: absolute;
    z-index: 1;
  }
  .footer {
    position: relative;
    z-index: 2;
  }
}
</style>
