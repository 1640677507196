// 弹窗自由拉伸&拖拽 自定义指令
import Vue from 'vue'
Vue.directive('dialogDragStretch', {
  bind(el, binding, vnode, oldVnode) {
    // 弹框可拉伸最小宽高
    const minWidth = 700
    const minHeight = 300
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    // 弹窗
    const dragDom = el.querySelector('.el-dialog')
    // 全屏按钮
    dialogHeaderEl.insertAdjacentHTML(
      'beforeEnd',
      '<div class="bigbtn"><div class="changeBig"></div><div class="changeSmall"></div></div>'
    )
    dragDom.style.overflow = 'auto'

    dialogHeaderEl.style.cursor = 'move'
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)
    const moveDown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop
      // 获取到的值带px 正则匹配替换
      let styL, styT
      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
      } else {
        styL = +sty.left.replace(/\px/g, '')
        styT = +sty.top.replace(/\px/g, '')
      }
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX
        const t = e.clientY - disY
        // 移动当前元素

        dragDom.style.left = `${l + styL}px`
        dragDom.style.top = `${t + styT}px`

        if (t + styT < 10) {
          dragDom.style.top = '10px'
        }
        if (t + styT > 550) {
          dragDom.style.top = '550px'
        }
        if (l + styL < -500) {
          dragDom.style.left = '-500px'
        }
        if (l + styL > 1000) {
          dragDom.style.left = '1000px'
        }
        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      }
      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
    dialogHeaderEl.onmousedown = moveDown
    // 双击头部全屏效果
    // bigBtn.onclick = (e) => {
    //   if (isFullScreen === false) {
    //     changeBig.style.display = 'none'
    //     changeSmall.style.display = 'block'
    //     nowHight = dragDom.clientHeight
    //     nowWidth = dragDom.clientWidth
    //     nowMarginTop = dragDom.style.marginTop
    //     dragDom.style.left = 0
    //     dragDom.style.top = 0
    //     dragDom.style.height = '100VH'
    //     dragDom.style.width = '100VW'
    //     dragDom.style.marginTop = 0
    //     isFullScreen = true
    //     dialogHeaderEl.style.cursor = 'initial'
    //     dialogHeaderEl.onmousedown = null
    //   } else {
    //     changeBig.style.display = 'block'
    //     changeSmall.style.display = 'none'
    //     // dragDom.style.height = 'auto'
    //     dragDom.style.height = 'auto'
    //     dragDom.style.width = nowWidth + 'px'
    //     dragDom.style.marginTop = nowMarginTop
    //     isFullScreen = false
    //     dialogHeaderEl.style.cursor = 'move'
    //     dialogHeaderEl.onmousedown = moveDown
    //   }
    // }
    dragDom.onmousemove = function (e) {
      // console.log(dialogHeaderEl,dragDom.querySelector('.el-dialog__body'), 123);
      // const moveE = e
      if (
        e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 ||
        dragDom.offsetLeft + 10 > e.clientX
      ) {
        dragDom.style.cursor = 'w-resize'
      } else if (
        el.scrollTop + e.clientY >
        dragDom.offsetTop + dragDom.clientHeight - 10
      ) {
        dragDom.style.cursor = 's-resize'
      } else {
        dragDom.style.cursor = 'default'
        dragDom.onmousedown = null
      }
      dragDom.onmousedown = (e) => {
        const clientX = e.clientX
        const clientY = e.clientY
        const elW = dragDom.clientWidth
        const elH = dragDom.clientHeight
        const EloffsetLeft = dragDom.offsetLeft
        const EloffsetTop = dragDom.offsetTop
        dragDom.style.userSelect = 'none'
        const ELscrollTop = el.scrollTop
        // 判断点击的位置是不是为头部
        if (
          clientX > EloffsetLeft &&
          clientX < EloffsetLeft + elW &&
          clientY > EloffsetTop &&
          clientY < EloffsetTop + 100
        ) {
          // 如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;
        } else {
          document.onmousemove = function (e) {
            e.preventDefault() // 移动时禁用默认事件
            // 左侧鼠标拖拽位置

            if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
              // 往左拖拽
              if (clientX > e.clientX) {
                dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px'
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                if (dragDom.clientWidth < minWidth) {
                  // 拖动
                } else {
                  dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px'
                }
              }
            }
            // 右侧鼠标拖拽位置
            if (
              clientX > EloffsetLeft + elW - 10 &&
              clientX < EloffsetLeft + elW
            ) {
              // 往左拖拽
              if (clientX > e.clientX) {
                if (dragDom.clientWidth < minWidth) {
                  // 拖动
                } else {
                  dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px'
                }
              }
              // 往右拖拽
              if (clientX < e.clientX) {
                dragDom.style.width = elW + (e.clientX - clientX) * 2 + 'px'
              }
            }
            // 底部鼠标拖拽位置
            if (
              ELscrollTop + clientY > EloffsetTop + elH - 20 &&
              ELscrollTop + clientY < EloffsetTop + elH
            ) {
              // 往上拖拽
              if (clientY > e.clientY) {
                if (dragDom.clientHeight < minHeight) {
                  // 拖动
                } else {
                  dragDom.style.height = elH - (clientY - e.clientY) + 'px'
                }
              }
              // 往下拖拽
              if (clientY < e.clientY) {
                dragDom.style.height = elH + (e.clientY - clientY) + 'px'
              }
            }
          }
          // 拉伸结束
          document.onmouseup = function (e) {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    }
  }
})
